import React from 'react';
import {Container,Row,Col} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import {FooterSection,FooterCol,
    FooterLi,FooterSocial,BottomFooterPara,BottomFooterRight,
    FooterAddWrapper,BottomFooterWrapper,BottomLink,FbIcon,TwitterIcon,LinkedinIcon,
    InstaIcon} from './footer.style';
import Fade from 'react-reveal/Fade';
import ScrollSpy from 'react-scrollspy';
import AnchorLink from 'react-anchor-link-smooth-scroll';

const FooterCustom = ({data}) =>{


    return (
        <div>
        <FooterSection id="footerContainer">
            <Container>
                <Fade bottom delay={1*data.DelayConstant}>
                <Row>
                    <Col md="3">
                        <FooterCol>
                            <img src={data.FooterLogo} alt=""/>
                        </FooterCol>
                    </Col>
                    <Col md="3">
                        {
                            data.FooterCol2 ? (
                                <FooterCol>
                                    <h5>{data.FooterCol2.FooterTitle}</h5>
                                    <ScrollSpy offset={-59} items={data.Items} currentClassName="is-current">
                                    {
                                        data.FooterCol2.FooterUl.map((item,idx) => {
                                            
                                            return item.LocalLink ? (
                                                
                                                <FooterLi>
                                                    <AnchorLink offset={55} href={item.Href}>
                                                        {item.FooterMenu}
                                                    </AnchorLink>
                                                </FooterLi>
                                                
                                            ) : (
                                                <FooterLi>
                                                    <a href={item.Href} target="_blank" rel="noreferrer">{item.FooterMenu}</a>                                                 
                                                </FooterLi>
                                            )
                                            
                                        })
                                    }
                                    </ScrollSpy>
                                </FooterCol>
                            ) : null
                        }
                        
                    </Col>
                    <Col md="3">
                        {
                            data.FooterCol3 ? (
                                <FooterCol>
                                    <h5>{data.FooterCol3.FooterTitle}</h5>
                                    {
                                        data.FooterCol3.FooterUl.map((item,idx) => {
                                        return <FooterLi>
                                            <a href={item.Href}>
                                                {item.FooterMenu}
                                            </a>
                                        </FooterLi>
                                        })
                                    }
                                </FooterCol>
                            ) : null
                        }
                        
                    </Col>
                    <Col md="3">
                        {
                            data.FooterCol4 ? (
                                <FooterCol>
                                <h5>{data.FooterCol4.FooterTitle}</h5>
                                {
                                    data.FooterCol4.FooterAddWrapper.map((item,idx) => {
                                    return <FooterAddWrapper>
                                            <img src={item.FooterAddImg} alt=""/>
                                            <p>{item.FooterAddText}</p>
                                        </FooterAddWrapper>
                                    })
                                }
                                </FooterCol>
                            ) : null
                        }
                        

                       
                    </Col>
                </Row>
                </Fade>
            </Container>
        </FooterSection>
        <BottomFooterWrapper>
            <Container>
                <Fade bottom delay={2*data.DelayConstant}>
                <Row>
                    <Col md="6">
                        <BottomFooterPara>
                            {data.BottomFooterPara}
                            <BottomLink rel="noreferrer" href={data.BottomFooterLink} target="_blank">
                            {data.BottomFooterName}
                            </BottomLink>
                        </BottomFooterPara>
                    </Col>
                    <Col md="6">
                        <BottomFooterRight>
                        {
                        data.SocialLinks.FacebookLink ? 
                            <FooterSocial href={data.SocialLinks.FacebookLink} aria-label="Facebook Link" target="_blank">
                                <FbIcon/>
                            </FooterSocial> :
                            null
                        }

                        {data.SocialLinks.TwitterLink ? 
                            <FooterSocial href={data.SocialLinks.TwitterLink}  aria-label="Twitter Link" target="_blank">
                            <TwitterIcon/>
                            </FooterSocial> :
                            null
                        }

                        {data.SocialLinks.LinkedinLink ? 
                            <FooterSocial href={data.SocialLinks.LinkedinLink}  aria-label="LinkedIn Link" target="_blank">
                            <LinkedinIcon/>
                            </FooterSocial>:
                            null
                        }


                        {data.SocialLinks.InstagramLink ? 
                            <FooterSocial href={data.SocialLinks.InstagramLink}  aria-label="Instagram Link" target="_blank">
                            <InstaIcon/>
                            </FooterSocial> :
                            null
                        }

                           
                        
                        
                        </BottomFooterRight>
                    </Col>
                </Row>
                </Fade>
            </Container>
        </BottomFooterWrapper>
        </div>
    );
}

export default FooterCustom;
